import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 320px;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
