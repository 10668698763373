import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';

import { GET_USER_SETTINGS } from 'graphql/settings.query';

import CommissionIcon from 'components/icons/CommissionIcon';
import LoginIcon from 'components/icons/LoginIcon';
import EmailIcon from 'components/icons/EmailIcon';

import { bigIntToPercent } from 'utils/helpers/converters';
import { useCoins } from 'contexts/coins.context';
import { useUser } from 'contexts/user.context';

import {
  AccentText,
  DetailGroup,
  IconContainer,
  IconWithText,
  SmallText,
  Text,
  Wrapper
} from './account-details.style';

const AccountDetails = () => {
  const { t } = useTranslation();
  const { coins } = useCoins();
  const { user } = useUser();
  const [result, setResult] = useState(null);
  const [settings, setSettings] = useState([]);

  const [loadUserSettings1, { data, loading }] = useLazyQuery(
    GET_USER_SETTINGS,
    {
      context: { clientName: 'private' }
    }
  );

  const [loadUserSettings2, { data: dataSettings, loading: dataLoading }] =
    useLazyQuery(GET_USER_SETTINGS, {
      context: { clientName: 'private' }
    });

  useEffect(() => {
    if (coins.length > 0) {
      if (coins[0]?.id) {
        loadUserSettings1({
          variables: { coinId: coins[0].id }
        });
      }
      if (coins[1]?.id) {
        loadUserSettings2({
          variables: { coinId: coins[1].id }
        });
      }
    }
  }, [coins, loadUserSettings1, loadUserSettings2]);

  useEffect(() => {
    if (!loading && data?.userSettings) {
      setSettings((prev) => [...prev, data?.userSettings]);
    }
    if (!dataLoading && dataSettings?.userSettings) {
      setSettings((prev) => [...prev, dataSettings?.userSettings]);
    }
  }, [data?.userSettings, loading, dataLoading, dataSettings?.userSettings]);

  useEffect(() => {
    if (settings.length > 0) {
      const setting = settings?.reduce((prev, curr) => {
        return Number(prev.reward?.poolFee) < Number(curr.reward?.poolFee)
          ? prev
          : curr;
      }, {});

      if (Object.keys(setting).length > 0) {
        setResult(setting);
      }
    }
  }, [settings]);

  return (
    <Wrapper>
      <DetailGroup>
        <IconWithText>
          <IconContainer>
            <LoginIcon />
          </IconContainer>
          <AccentText>{t('account.login')}</AccentText>
        </IconWithText>
        <Text className="small">{user !== null ? user?.userName : ''}</Text>
      </DetailGroup>
      <DetailGroup>
        <IconWithText>
          <IconContainer>
            <EmailIcon />
          </IconContainer>
          <AccentText>{t('account.email')}</AccentText>
        </IconWithText>
        <Text>{user !== null ? user?.email : ''}</Text>
      </DetailGroup>
      <DetailGroup>
        <IconWithText>
          <IconContainer>
            <CommissionIcon />
          </IconContainer>
          <AccentText>{t('account.poolCommission')}</AccentText>
        </IconWithText>
        <Text>{result ? bigIntToPercent(result?.reward?.poolFee) : ''}%</Text>
        {!!result?.reward?.poolFeeMessage && (
          <SmallText>
            <a href="https://jethash.io/" target="_blank" rel="noreferrer">
              JetHash.io
            </a>{' '}
            {t('account.optionalText')}
          </SmallText>
        )}
      </DetailGroup>
    </Wrapper>
  );
};

export default AccountDetails;
