import { useTranslation } from 'react-i18next';

import Heading from 'components/common/heading/heading';
import { useUI } from 'contexts/ui.context';
import { Section } from 'assets/styles/pages.style';
import { Button } from 'components/common/button/button';
import { BaseForm } from 'components/common/base-form/base-form';
import { Field } from './password-setting.style';

const PasswordSetting = () => {
  const { t } = useTranslation();

  const { openModal, openResetPassModal } = useUI();

  const openModalHandler = (e) => {
    e.preventDefault();
    openResetPassModal();
    openModal();
  };

  return (
    <Section>
      <Heading variant="h4" mb={4}>
        {t('account.passwordSetting')}
      </Heading>

      <BaseForm>
        <Field>{t('account.text')}</Field>
        <Button onClick={openModalHandler}>{t('account.passwordReset')}</Button>
      </BaseForm>
    </Section>
  );
};

export default PasswordSetting;
