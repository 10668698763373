import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4px;
  width: 100%;
  max-width: ${(props) => (props.width ? props.width : '100%')};
  padding: 4px;
  background-color: ${themeGet('colors.primary.light')};
  border-radius: ${themeGet('radii.base')};

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;
