import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from 'contexts/user.context';
import { useUI } from 'contexts/ui.context';
import { Button } from './toggler-button';
import { ButtonWrapper } from './toggler.styles';

const TogglerItem = ({ item: { value, text, state } }) => {
  const { t } = useTranslation();
  const [activeValue, handler, extraHandler] = state;

  const onClickHandler = (e) => {
    e.preventDefault();
    handler(value);
    extraHandler();
  };

  return (
    <Button
      onClick={activeValue === value ? undefined : onClickHandler}
      active={activeValue === value}
    >
      {t(text)}
    </Button>
  );
};

const TwoFaToggler = () => {
  const { user } = useUser();
  const {
    openModal,
    displayTwoFaToggler,
    setActiveTwoFaToggler,
    openVerifyModal
  } = useUI();

  useEffect(() => {
    if (user?.security?.twoFA?.active) {
      setActiveTwoFaToggler(user.security.twoFA.active);
    }
  }, [user?.security?.twoFA?.active]);

  const openDisableTwoFA = () => {
    openVerifyModal(false);
    openModal();
  };

  const openEnableTwoFA = () => {
    openVerifyModal(true);
    openModal();
  };

  const data = [
    {
      value: false,
      text: 'twoFA.off',
      state: [displayTwoFaToggler, setActiveTwoFaToggler, openDisableTwoFA]
    },
    {
      value: true,
      text: 'twoFA.on',
      state: [displayTwoFaToggler, setActiveTwoFaToggler, openEnableTwoFA]
    }
  ];

  return (
    <ButtonWrapper>
      {data.map((item) => (
        <TogglerItem key={item.text} item={item} />
      ))}
    </ButtonWrapper>
  );
};

export default TwoFaToggler;
