import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 28px;
  border-radius: ${themeGet('radii.base')};
  box-shadow: ${themeGet('shadows.section')};

  @media ${themeGet('mediaQueries.mobileOnly')} {
    gap: 30px;
  }

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const DetailGroup = styled.div`
  display: flex;
  align-items: center;

  @media ${themeGet('mediaQueries.mobileOnly')} {
    flex-direction: column;
  }
`;

export const AccentText = styled.p`
  margin: 0 10px;
  color: ${themeGet('colors.primary.regular')};
  font-weight: ${themeGet('fontWeights.bold')};
`;

export const SmallText = styled.span`
  margin-left: 10px;
  font-size: ${themeGet('fontSizes.sm')}px;
`;

export const Text = styled.span`
  font-size: ${themeGet('fontSizes.base')}px;

  &.small {
    @media ${themeGet('mediaQueries.mobileOnly')} {
      font-size: ${themeGet('fontSizes.xs')}px;
    }
  }
`;

export const IconWithText = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const IconContainer = styled.div`
  line-height: 1;
`;
