import { memo } from 'react';
import { navigate } from 'gatsby';

import { AUTH_LOGIN_PAGE } from 'site-settings/site-navigation';

import { useUser } from 'contexts/user.context';

const PrivateRoute = ({ component: Component, location, ...props }) => {
  const { isAuthenticated } = useUser();

  if (!isAuthenticated && location.pathname !== AUTH_LOGIN_PAGE) {
    navigate(AUTH_LOGIN_PAGE);
    return null;
  }

  return <Component {...props} />;
};

export default memo(PrivateRoute);
