import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const BaseForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    flex-direction: row;
  }

  @media (max-width: 575px) {
    button {
      width: 100%;
    }
  }
`;
