const CommissionIcon = ({ width = 19, height = 19 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.1802V18.8202C9 19.4602 8.41 19.9402 7.79 19.8002C3.32 18.8002 0 14.7902 0 10.0002C0 5.2102 3.32 1.2002 7.79 0.200198C8.41 0.0601984 9 0.540198 9 1.1802ZM11.03 1.1802V7.9902C11.03 8.5402 11.48 8.9902 12.03 8.9902H18.82C19.46 8.9902 19.94 8.4002 19.8 7.7702C18.95 4.0102 16 1.0502 12.25 0.200198C11.62 0.0601984 11.03 0.540198 11.03 1.1802ZM11.03 12.0102V18.8202C11.03 19.4602 11.62 19.9402 12.25 19.8002C16.01 18.9502 18.96 15.9802 19.81 12.2202C19.95 11.6002 19.46 11.0002 18.83 11.0002H12.04C11.48 11.0102 11.03 11.4602 11.03 12.0102Z"
        fill="#6487FF"
      />
    </svg>
  );
};

export default CommissionIcon;
