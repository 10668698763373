import { useTranslation } from 'react-i18next';

import SEO from 'components/seo';

import { useUser } from 'contexts/user.context';
import { MainContentArea, Container, Row } from 'assets/styles/pages.style';
import Heading from 'components/common/heading/heading';
import AccountDetails from 'components/account-details/account-details';
import Referral from 'components/referral/referral';
import PasswordSetting from 'components/password-setting/password-setting';
import CallToAction from 'components/call-to-action/call-to-action';
import TwoFA from 'components/two-fa/two-fa';

export default function AccountPage() {
  const { t } = useTranslation();
  const { user } = useUser();
  const referral = `${process.env.GATSBY_SITE_URL}/?referral=${user?.referral?.code}`;

  return (
    <MainContentArea>
      <Container>
        <Row mb={4}>
          <Heading variant="h3" mb={4}>
            {t('account.title')}
          </Heading>
        </Row>
        <Row>
          <AccountDetails />
        </Row>
        {user?.referral?.active && (
          <Row>
            <Referral referral={referral} />
          </Row>
        )}
        <Row>
          <TwoFA />
        </Row>
        <Row>
          <PasswordSetting />
        </Row>
        <Row>
          <CallToAction />
        </Row>
      </Container>
    </MainContentArea>
  );
}
