import { Router } from '@reach/router';

import PrivateRoute from 'components/private-route';
import Account from 'components/private/account';
import { useTranslation } from 'react-i18next';
import SEO from 'components/seo';

const AccountPage = () => (
  <Router basepath="/account">
    <PrivateRoute path="/" component={Account} />
  </Router>
);

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('pageTitles.account.title')} />;
};

export default AccountPage;
