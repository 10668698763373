import { useTranslation } from 'react-i18next';

import Heading from 'components/common/heading/heading';
import { Section } from 'assets/styles/pages.style';
import { BaseForm } from 'components/common/base-form/base-form';
import { Button } from 'components/common/button/button';
import { Input } from 'components/common/form/input';

const Referral = ({ referral }) => {
  const { t } = useTranslation();
  const handleSubmit = (e) => e.preventDefault();
  const handleCopy = () => {
    navigator.clipboard.writeText(referral);
  };

  return (
    <Section>
      <Heading variant="h4" mb={4}>
        {t('account.referrals')}
      </Heading>

      <BaseForm onSubmit={handleSubmit}>
        <Input value={referral} readonly />
        <Button onClick={handleCopy}>{t('account.copy')}</Button>
      </BaseForm>
    </Section>
  );
};

export default Referral;
