import { useTranslation } from 'react-i18next';

import { Section } from 'assets/styles/pages.style';
import Heading from 'components/common/heading/heading';
import TwoFaToggler from 'components/common/toggler/base-toggler';

import { Wrapper } from './two-fa.style';

const TwoFA = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Heading variant="h4" mb={4}>
        {t('twoFA.title')}
      </Heading>
      <Wrapper>
        <TwoFaToggler />
      </Wrapper>
      <p>{t('twoFA.info')}</p>
    </Section>
  );
};

export default TwoFA;
