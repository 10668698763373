import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Field = styled.div`
  width: 100%;
  padding: 17px 20px;
  text-align: center;
  border-radius: ${themeGet('radii.base')};
  border: 1px solid ${themeGet('colors.input.border')};
`;
